<template>
  <div>
    <h2 class="pl-2">
      {{!this.isAdd ? 'Edytuj' : 'Dodaj nową'}} ofertę
    </h2>
    <v-form v-model="valid" ref="articleForm" lazy-validation class="pb-10">
      <v-card class="mt-10 pa-5">
        <v-row>
          <v-col
            cols="9">
            <h4
              class="mb-5">
              Dane podstawowe
            </h4>
          </v-col>

          <v-col
            v-if="!this.isAdd"
            class="text-right"
            cols="3">
            <v-btn
              color="red"
              class="mr-10 white--text"
              @click="deleteArticle()"
            >
            {{ $t('common.delete') }} ofertę
            <v-icon class="ml-2">mdi-delete</v-icon>
            </v-btn>
          </v-col>
      </v-row>

      <v-row>
          <v-col cols="6">
            <v-select :items="[
                        {name:'Dla Ciebie', id:0},
                        {name:'Dla Sprzedawcy', id:1}
                      ]"
                      label="typ oferty"
                      :item-text="'name'"
                      :item-value="'id'" required disabled
                      v-model='article.type'></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
                      multiple
                      ref="groupSelect"
                      :items="groups"
                      label="Grupa"
                      :placeholder="'Wszystkie'"
                      :item-text="'name'"
                      :item-value="'id'"
                      v-model='article.groupsIds'
                      persistent-placeholder
              ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <DatePickerInput v-model="article.from" :labelText="'Data od'" :rules="rules.required"></DatePickerInput>
          </v-col>
          <v-col cols="6">
            <DatePickerInput
              v-model="article.to"
              :labelText="'Data do'"
              :rules="rules.required"></DatePickerInput>
          </v-col>
        </v-row>

        <v-text-field label="Tytuł"
          v-model='article.name'
          :rules="[...rules.maxLengthTitle, ...rules.required]">
        </v-text-field>

        <v-textarea
          auto-grow
          class="mb-5"
          label="Opis podstawowy"
          v-model='article.description'
          :rules="[...rules.maxLengthDescription, ...rules.required]">
        </v-textarea>

        <span class="custom-label">Plik</span>
        <div v-if="article.fileName">
          <span class="mr-5">
            {{article.fileOriginalName}}
          </span>
          <v-btn
              class="mr-5"
              right
              @click="removeFile()">
              Usuń plik
          </v-btn>
          <v-btn class="ml-2"
              @click="openFile(article.fileName)">
            Otwórz plik w nowej karcie
          </v-btn>
        </div>
        <v-file-input v-else
          counter
          show-size
          truncate-length="15"
          label="Miniaturka"
          v-model='article.file'
          accept=".png, .jpeg, .jpg, .mp4"
          :rules="[...rules.fileType, ...rules.fileSize]">
        </v-file-input>
      </v-card>

      <v-card
        class="mt-10 pa-5"
        v-for="(item, index) in article.articleDetails"
        :key="item.id">
        <AddArticleDetails
          :articleDetails="article.articleDetails[index]"
          :index="index" @remove="removeDetails">
        </AddArticleDetails>
      </v-card>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="!article.isArchival"
          color="green"
          class="mr-4 white--text mt-5 mr-10"
          @click="addDetails">
          Dodaj kolejną sekcję szczegółów
          <v-icon class="ml-2">mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card
        v-if="article.type == ArticleTypesEnum.YOU && article.calculator"
        class="mt-10 pa-5"
        >
        <h4 class="mb-5">
          Kalkulator
        </h4>
        <v-row>
          <v-col
            cols="6">
            <v-text-field label="Nazwa pakietu"
                v-model='article.calculator.name'
                :rules="[...rules.maxLengthTitle, ...rules.required]"
                >
              </v-text-field>
          </v-col>

          <v-col
            cols="6">
            <v-text-field
              label="Wielkość pakietu"
              v-model='article.calculator.subName'
              :rules="[...rules.maxLengthTitle, ...rules.required]">

              </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6">
              <v-text-field
                label="Cena"
                type="number"
                value="0.00"
                prefix="zł"
                v-model='article.calculator.grossPrice'
                :rules="rules.required">
              </v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="article.type == ArticleTypesEnum.TRADER"
        class="mt-10 pa-5"
      >
        <v-card flat>
          <v-checkbox v-model="article.isShakeGameEnabled">
            <template v-slot:label>
              <div>
                <h4 style="color: #000000DE">Nagrodomat</h4>
              </div>
            </template>
          </v-checkbox>
        </v-card>
        <v-card v-if="article.isShakeGameEnabled" flat>
          <v-row>
            <v-col cols="6">
              <DatePickerInput v-model="article.shakeGame.from" :labelText="'Data od'"
                               :rules="rules.required"></DatePickerInput>
            </v-col>
            <v-col cols="6">
              <DatePickerInput
                v-model="article.shakeGame.to"
                :labelText="'Data do'"
                :rules="rules.required"></DatePickerInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                auto-grow
                class="mb-5"
                label="Opis nagrodomatu"
                v-model='article.shakeGame.description'
                :rules="[...rules.maxLengthDescription, ...rules.required]">
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="article.shakeGame.fromHour"
                    label="Czynny od"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="24hr"
                  v-model="article.shakeGame.fromHour"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="article.shakeGame.toHour"
                    label="Czynny do"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="24hr"
                  v-model="article.shakeGame.toHour"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <template>
                  <v-text-field
                    v-model="article.shakeGame.shakeIntervalTime"
                    label="Odstęp między kolejnym losowaniem w minutach"
                    prepend-icon="mdi-timer-lock-outline"
                    @input="limitShakeIntervalTime"
                    type="number"
                    max="60"
                  ></v-text-field>
                </template>
            </v-col>
          </v-row>
          <v-card flat>
            <span class="custom-label">Zdjęcia nagrodomatu</span>
            <v-row v-for="(file, index) in article.shakeGame.files" :key="index" class="pa-3">
              <AddShakeGameFiles
                :index="index"
                :shakeGameFile="file"
                title="Zdjęcie nagrodomatu"
                @removeFile="removeShakeGameFile(index)"
              ></AddShakeGameFiles>
            </v-row>
            <v-btn
              v-if="article.isShakeGameEnabled && article.shakeGame.files.length < maxShakeGameFilesCount"
              color="green"
              class="mr-4 white--text mt-5 mr-10"
              @click="addShakeGameFile"
            >
              Dodaj kolejny plik
              <v-icon class="ml-2">mdi-file-plus</v-icon>
            </v-btn>
          </v-card>
        </v-card>
      </v-card>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="!article.isArchival"
          :disabled="!isAdd && isSaveChangesBtnDisabled ? true : false"
          color="blue"
          class="mr-4 mt-5 mb-5 white--text mr-10"
          @click="openNotificationDialog">
          {{ !isAdd ? 'Zapisz zmiany' : 'Dodaj ofertę'}}
          <v-icon class="ml-2">mdi-archive-arrow-up</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>

    <confirm-delete-dialog
        endPoint="/Article/DeleteArticle"
        ref="confirmDeleteDialog"
        @submit="$router.push({ name: article.type !== ArticleTypesEnum.YOU
            ? 'OfferTrader'
            : 'OfferYou'}
            )"
      ></confirm-delete-dialog>
    <article-notification-dialog
      v-model="pushNotification"
      ref="articleNotificationDialog"
      @submit="submit">
    </article-notification-dialog>
  </div>
</template>

<script>
import {
  displayInfo,
  displayErrors
} from '@/utils/helpers'
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'
import DatePickerInput from '@/components/DatePickerInput.vue'
import AddArticleDetails from '@/views/Article/AddArticleDetails.vue'
import ArticleNotificationDialog from '@/views/Article/ArticleNotificationDialog.vue'
import { ArticleTypesEnum } from '@/utils/enums.js'
import AddShakeGameFiles from '@/views/Article/AddShakeGameFiles.vue'

export default {
  props: ['type'],
  name: 'AddOrEditArticle',
  components: {
    DatePickerInput,
    AddArticleDetails,
    ConfirmDeleteDialog,
    AddShakeGameFiles,
    ArticleNotificationDialog
  },
  data: () => ({
    rules: {
      required: [v => !!v || 'Pole wymagane'],
      maxLengthTitle: [v => v.length <= 256 || 'Maksymalna ilosć 256 znaków.'],
      maxLengthDescription: [v => v.length <= 4096 || 'Maksymalna ilosć 4096 znaków.'],
      fileType: [v => !v || !!(/(\.jpg|\.jpeg|\.mp4|\.gif|\.png)$/i.exec(v.name)) || 'Błędny plik. Dozwolone rozszerzenie to .jpg, .jpeg, .mp4, .gif, .png'],
      fileSize: [v => !v || v.size < 30000000 || 'Wielkość pliku musi być mniejsza od 30MB']
    },
    isAdd: false,
    isSaveChangesBtnDisabled: false,
    valid: false,
    menu: false,
    article: {
      id: null,
      version: null,
      groupsIds: [],
      name: '',
      description: '',
      file: null,
      fileName: null,
      fileOriginalName: null,
      type: 0,
      from: '',
      to: '',
      isArchival: false,
      articleDetails: [],
      calculator: {
        name: '',
        subName: '',
        grossPrice: 0
      },
      shakeGame: {
        from: '',
        to: '',
        description: '',
        fromHour: '',
        toHour: '',
        files: [{
          file: null,
          fileName: '',
          fileOriginalName: ''
        }],
        shakeIntervalTime: ''
      },
      isShakeGameEnabled: false
    },
    maxShakeGameFilesCount: 4,
    articleOrginal: null,
    groups: [],
    pushNotification: {
      isAdd: false,
      none: false,
      sendOn: '',
      title: '',
      description: '',
      file: null,
      minDate: null
    }
  }),
  async created () {
    this.ArticleTypesEnum = ArticleTypesEnum
    const id = this.$route.params.id
    this.article.type = this.type
    if (id) {
      this.article.id = id
      await this.getDataAsync()
    } else {
      this.addDetails()
    }

    this.isAdd = this.article.id === undefined ||
      this.article.id == null ||
      this.article.id === ''

    const groupsResult = await this.$http.get('/Group/GetGroups')
    this.groups = groupsResult.data

    this.$watch(
      'article.type', function (newVal) {
        if (newVal === ArticleTypesEnum.YOU) {
          this.initCalculator()
        } else {
          this.calculator = null
        }
      }
    )
  },
  methods: {
    async getDataAsync () {
      const result = await this.$http.get(`/Article/GetArticle?Id=${this.article.id}`)
      this.article = result.data
      if (this.article.shakeGame === null) {
        this.article.shakeGame = {
          from: '',
          to: '',
          description: '',
          fromHour: '',
          toHour: '',
          file: null,
          fileName: null,
          fileOriginalName: null,
          files: [{
            file: null,
            fileName: '',
            fileOriginalName: ''
          }],
          shakeIntervalTime: ''
        }
      }
      this.articleOrginal = JSON.stringify(result.data)
    },
    addDetails () {
      this.article.articleDetails.push({
        id: null,
        name: '',
        description: '',
        file: null,
        fileName: null,
        fileOriginalName: null
      })
    },
    removeDetails (index) {
      this.article.articleDetails.splice(index, 1)
    },
    initCalculator () {
      this.article.calculator = {
        name: '',
        subName: '',
        grossPrice: 0
      }
    },
    removeFile () {
      this.article.fileName = null
      this.article.fileOriginalName = null
    },
    removeShakeGameFile (fileIndex) {
      this.article.shakeGame.files.splice(fileIndex, 1)
      if (this.article.shakeGame.files.length === 0) {
        this.addShakeGameFile()
      }
    },
    addShakeGameFile () {
      this.article.shakeGame.files.push({
        file: null,
        fileOriginalName: '',
        fileName: ''
      })
    },
    openFile (fileName) {
      window.open(`${process.env.VUE_APP_BASE}uploads/${fileName}`, '_blank')
    },
    deleteArticle () {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.id = this.article.id
    },
    openNotificationDialog () {
      if (this.isArticleNotChanged()) {
        displayInfo('Brak zmian do zapisania', 'info')
        return
      }
      this.pushNotification.none = false
      this.$refs.articleForm.validate()
      const now = new Date().toISOString()
      this.pushNotification.minDate = this.article.from < now ? now : this.article.from
      this.pushNotification.isAdd = this.isAdd

      if (!this.$refs.articleForm.validate()) {
        displayErrors('Uzupełnij wymagane pola lub popraw błędne wartości.')
        return
      }
      this.$refs.articleNotificationDialog.dialog = true
    },
    isArticleNotChanged () {
      return this.articleOrginal === JSON.stringify(this.article)
    },
    async submit () {
      const formData = new FormData()

      formData.append('Article.Id', this.article.id)
      formData.append('Article.Name', this.article.name)
      formData.append('Article.Description', this.article.description)
      formData.append('Article.File', this.article.file)
      formData.append('Article.FileName', this.article.fileName ? this.article.fileName : '')
      formData.append('Article.Type', this.article.type)
      formData.append('Article.From', this.article.from)
      formData.append('Article.To', this.article.to)

      if (!this.isAdd) {
        formData.append('Article.Version', this.article.version)
      }

      for (let i = 0; i < this.article.groupsIds.length; i++) {
        formData.append(`Article.GroupsIds[${i}]`, this.article.groupsIds[i])
      }

      if (this.article.type === ArticleTypesEnum.YOU) {
        formData.append('Article.Calculator.Name', this.article.calculator.name)
        formData.append('Article.Calculator.SubName', this.article.calculator.subName)
        formData.append('Article.Calculator.GrossPrice', `${this.article.calculator.grossPrice}`.replace('.', ','))
      }

      for (let i = 0; i < this.article.articleDetails.length; i++) {
        formData.append(`Article.ArticleDetails[${i}].Name`, this.article.articleDetails[i].name)
        formData.append(`Article.ArticleDetails[${i}].Description`, this.article.articleDetails[i].description)
        formData.append(`Article.ArticleDetails[${i}].File`, this.article.articleDetails[i].file)
        formData.append(`Article.ArticleDetails[${i}].FileName`, this.article.articleDetails[i].fileName ? this.article.articleDetails[i].fileName : '')
      }

      if (this.pushNotification.none === false) {
        formData.append('Article.PushNotification.Title', this.pushNotification.title)
        formData.append('Article.PushNotification.Description', this.pushNotification.description)
        formData.append('Article.PushNotification.File', this.pushNotification.file)
        formData.append('Article.PushNotification.SendOn', new Date(this.pushNotification.sendOn).toISOString())
      }

      if (this.article.type === ArticleTypesEnum.TRADER && this.article.isShakeGameEnabled === true) {
        formData.append('Article.ShakeGame.From', this.article.shakeGame.from)
        formData.append('Article.ShakeGame.To', this.article.shakeGame.to)
        formData.append('Article.ShakeGame.FromHour', this.article.shakeGame.fromHour + ':00')
        formData.append('Article.ShakeGame.ToHour', this.article.shakeGame.toHour + ':00')
        if (this.article.shakeGame.shakeIntervalTime === 60 || this.article.shakeGame.shakeIntervalTime === '60') {
          formData.append('Article.ShakeGame.ShakeIntervalTime', '01:00:00')
        } else {
          formData.append('Article.ShakeGame.ShakeIntervalTime', '00:' + this.article.shakeGame.shakeIntervalTime + ':00')
        }
        formData.append('Article.ShakeGame.Description', this.article.shakeGame.description)
        this.article.shakeGame.files.forEach((item, index) => {
          formData.append(`Article.ShakeGame.Files[${index}].File`, item.file)
          formData.append(`Article.ShakeGame.Files[${index}].FileName`, item.fileName ? item.fileName : '')
        })
      }

      try {
        const result = await this.$http.post(
          this.isAdd
            ? '/Article/AddArticle'
            : '/Article/UpdateArticle', formData
        )

        if (result.status === 200) {
          this.$refs.articleNotificationDialog.dialog = false
          const info = `Poprawnie ${this.isAdd ? 'dodano' : 'zaktualizowano'} ofertę: ${this.article.name}`
          displayInfo(info)

          const routeName = this.article.type === ArticleTypesEnum.YOU
            ? 'OfferYou'
            : 'OfferTrader'

          await this.$router.push({
            name: routeName
          }
          )
        } else {
          displayErrors('Wystapił błąd')
        }
      } catch (e) {
      } finally {
        this.submitLoading = false
      }

      if (this.$refs.articleNotificationDialog?.isLoading) {
        this.$refs.articleNotificationDialog.isLoading = false
      }
    },
    limitShakeIntervalTime () {
      if (parseFloat(this.article.shakeGame.shakeIntervalTime) > 60) {
        this.$nextTick(() => {
          this.article.shakeGame.shakeIntervalTime = 60
        })
      }
      if (parseFloat(this.article.shakeGame.shakeIntervalTime) < 0) {
        this.$nextTick(() => {
          this.article.shakeGame.shakeIntervalTime = 0
        })
      }
    }
  },
  watch: {
    article: {
      handler () {
        if (!this.isAdd) {
          if (!this.isArticleNotChanged()) {
            this.isSaveChangesBtnDisabled = false
          } else {
            this.isSaveChangesBtnDisabled = true
          }
        }
      },
      deep: true
    }
  }
}
</script>
