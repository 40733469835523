<template>
  <div>
    <span class="custom-label">Plik</span>
    <v-btn
      v-if="index > 0 && shakeGameFile.fileName === ''"
      color="orange"
      class="float-right mr-10 white--text"
      @click="removeFile()"
    >
      Usuń sekcję
      <v-icon class="ml-2">mdi-window-close</v-icon>
    </v-btn>
    <div style="clear:both"></div>
    <div v-if="shakeGameFile.fileName">
          <span class="mr-5">
            {{shakeGameFile.fileOriginalName}}
          </span>
      <v-btn
        class="mr-5"
        right
        @click="removeFile()">
        Usuń plik
      </v-btn>
      <v-btn class="ml-2"
             @click="openFile(shakeGameFile.fileName)">
        Otwórz plik w nowej karcie
      </v-btn>
    </div>
    <v-file-input
      v-else
      counter
      show-size
      truncate-length="15"
      label="Wybierz Plik"
      v-model="shakeGameFile.file"
      accept=".png, .jpeg, .jpg, .mp4"
      :rules="[...rules.fileType, ...rules.fileSize, ...rules.required]"
    ></v-file-input>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    shakeGameFile: {
      file: null,
      fileOriginalName: '',
      fileName: ''
    }
  },
  data: () => ({
    rules: {
      fileType: [
        v =>
          !v ||
          !!(/(\.jpg|\.jpeg|\.mp4|\.gif|\.png)$/i.exec(v.name)) ||
          'Błędny plik. Dozwolone rozszerzenie to .jpg, .jpeg, .mp4, .gif, .png'
      ],
      fileSize: [v => !v || v.size < 30000000 || 'Wielkość pliku musi być mniejsza od 30MB'],
      required: [v => !!v || 'Pole wymagane']
    }
  }),
  methods: {
    removeFile () {
      this.$emit('removeFile')
    },
    openFile (fileName) {
      window.open(`${process.env.VUE_APP_BASE}uploads/${fileName}`, '_blank')
    }
  }
}
</script>
